import styled from 'styled-components';

export const TotalWrapper = styled.div`
  width: 905px;
  height: 330px;
  margin-top: 10px;
`;

export const BannerWrap = styled.div``;
export const MainImg = styled.img`
  width: 905px;
  height: 317px;
`;
