export const colors = {
  green_main: '#0A1A16',
  green_main2: '#649D17',
  green_tag: '#D9F0AB',
  green_deep: '#1A2807',
  green_dark: '#111904',
  grey_15: '#262626',
  grey_40: '#666666',
  grey_50: '#808080',
  grey_60: '#999999',
  grey_70: '#B3B3B3',
  grey_80: '#CCCCCC',
  grey_90: '#E6E6E6',
  grey_95: '#F2F2F2',
  grey_100: '#FFFFFF',
  grey_200: '#a1a1a1',
  grey_300: '#767676',
  grey_400: '#5c5c5c',
  grey_500: '#333333',
  grey_600: '#2e2e2e',
  grey_700: '#242424',
  grey_800: '#1c1c1c',
  grey_900: '#151515',
  sub_light: '#FBF8F3',
};

export const typography = {
  display_1: {
    fontFamily: 'Merriweather',
    fontWeight: 600,
    fontSize: '30px',
    letterSpacing: '0.0073529412em',
  },
  s_title: {
    fontFamily: 'Merriweather',
    fontWeight: 500,
    fontSize: '16px',
  },
  headline_1: {
    fontFamily: 'Noto Sans KR',
    fontWeight: 400,
    fontSize: '40px',
    letterSpacing: '0.001em',
  },
  subtitle_3: {
    fontFamily: 'Noto Sans KR',
    fontWeight: 600,
    fontSize: '14px',
    color: colors.grey_400,
    letterSpacing: '0.0071428571em',
  },
  subtitle_2: {
    fontFamily: 'Noto Sans KR',
    fontWeight: 500,
    fontSize: '0.875rem',
    letterSpacing: '0.0071428571em',
  },
  subtitle_1_SB: {
    fontFamily: 'Noto Sans KR',
    fontWeight: 600,
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
  subtitle_1: {
    fontFamily: 'Noto Sans KR',
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
  SUIT: {
    fontFamily: 'Noto Sans KR',
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
  SUIT_sub: {
    fontFamily: 'Noto Sans KR',
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '0.15px',
  },
  sub: {
    fontFamily: 'Noto Sans KR',
    fontWeight: 400,
    fontSize: '10px',
    letterSpacing: '0.15px',
  },
  body_1: {
    fontFamily: 'Noto Sans KR',
    fontSize: '16px',
    letterSpacing: '0.5px',
  },
  body_2: {
    fontFamily: 'Noto Sans KR',
    fontSize: '14px',
    letterSpacing: '0.25px',
  },
  body_3_SB: {
    fontFamily: 'Noto Sans KR',
    fontSize: '12px',
    fontWeight: 600,
    letterSpacing: '0.4px',
  },
  button: {
    fontFamily: 'Noto Sans KR',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '1.25px',
  },
  caption: {
    fontFamily: 'Noto Sans KR',
    fontSize: '12px',
  },
  overline: {
    fontFamily: 'Noto Sans KR',
    color: '#5c5c5c',
    fontSize: '12px',
    letterSpacing: '1.5px',
    fontWeight: 500,
  },
};
