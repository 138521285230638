import image1 from '../Assets/img/Shop/굿즈샵 상단배너.png';

const imageData = [
  {
    label: 'Image 1',
    alt: 'image1',
    url: image1,
  },

  {
    label: 'Image 2',
    alt: 'image2',
    url: image1,
  },

  {
    label: 'Image 3',
    alt: 'image3',
    url: image1,
  },
];

export default imageData;
